import $ from 'jquery'
import 'slick-carousel'
import { getSessionId } from '../../js/lib/split-testing'

$(document).ready(initPkgSliders)

if (
  window.location.pathname !== '/packages' &&
  window.location.pathname !== '/new-packages'
) {
  LotDropDown()
  PackageLotSelect()
}

// IsRecommended()

function initPkgSliders() {
  if ($('.js-pkghero-slider').length) {
    const sliders = $('.js-pkghero-slider:not(.slick-initialized)')
    if (sliders.length) {
      sliders.each(function () {
        let $el = $(this)
        let speed = $el.data('speed') * 1000
        $el.slick({
          arrows: true,
          prevArrow:
            '<button class="slick-prev button black"><span class="u-sr-only">Previous</span><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.29854 13.7837C7.66256 14.1018 8.21531 14.0647 8.5335 13.701C8.85137 13.337 8.8142 12.7842 8.4505 12.466L2.20331 7.00006L8.45083 1.53376C8.81484 1.21557 8.85169 0.662821 8.53382 0.299128C8.21563 -0.0648861 7.66288 -0.102057 7.29886 0.216135L0.29897 6.34124C0.108952 6.50723 4.44873e-06 6.74755 4.42665e-06 7.00006C4.40458e-06 7.25256 0.108952 7.49256 0.29897 7.65887L7.29854 13.7837Z" fill="white"/></svg></button>',
          nextArrow:
            '<button class="slick-next button black"><span class="u-sr-only">Next</span><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.70146 0.216343C1.33744 -0.101849 0.784695 -0.0646781 0.466503 0.299015C0.148632 0.663029 0.185802 1.21578 0.549496 1.53397L6.79669 6.99994L0.549173 12.4662C0.185159 12.7844 0.148309 13.3372 0.46618 13.7009C0.784372 14.0649 1.33712 14.1021 1.70114 13.7839L8.70103 7.65876C8.89105 7.49277 8.99999 7.25245 8.99999 6.99994C9 6.74744 8.89105 6.50744 8.70103 6.34113L1.70146 0.216343Z" fill="white"/></svg></button>',
          dots: true,
          autoplay: true,
          autoplaySpeed: speed,
          speed: 900,
        })
      })
    }
  }

  if ($('.js-pkgdetails-slider-for').length) {
    const sliders = $('.js-pkgdetails-slider-for:not(.slick-initialized)')
    if (sliders.length) {
      sliders.each(function () {
        let $el = $(this)
        $el.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.js-pkgdetails-slider-nav',
        })
      })
    }
  }

  if ($('.js-pkgdetails-slider-nav').length) {
    const sliders = $('.js-pkgdetails-slider-nav:not(.slick-initialized)')
    if (sliders.length) {
      sliders.each(function () {
        let $el = $(this)
        let speed = $el.data('speed') * 1000
        $el.slick({
          arrows: true,
          prevArrow:
            '<button class="slick-prev button black"><span class="u-sr-only">Previous</span><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.29854 13.7837C7.66256 14.1018 8.21531 14.0647 8.5335 13.701C8.85137 13.337 8.8142 12.7842 8.4505 12.466L2.20331 7.00006L8.45083 1.53376C8.81484 1.21557 8.85169 0.662821 8.53382 0.299128C8.21563 -0.0648861 7.66288 -0.102057 7.29886 0.216135L0.29897 6.34124C0.108952 6.50723 4.44873e-06 6.74755 4.42665e-06 7.00006C4.40458e-06 7.25256 0.108952 7.49256 0.29897 7.65887L7.29854 13.7837Z" fill="white"/></svg></button>',
          nextArrow:
            '<button class="slick-next button black"><span class="u-sr-only">Next</span><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.70146 0.216343C1.33744 -0.101849 0.784695 -0.0646781 0.466503 0.299015C0.148632 0.663029 0.185802 1.21578 0.549496 1.53397L6.79669 6.99994L0.549173 12.4662C0.185159 12.7844 0.148309 13.3372 0.46618 13.7009C0.784372 14.0649 1.33712 14.1021 1.70114 13.7839L8.70103 7.65876C8.89105 7.49277 8.99999 7.25245 8.99999 6.99994C9 6.74744 8.89105 6.50744 8.70103 6.34113L1.70146 0.216343Z" fill="white"/></svg></button>',
          dots: true,
          autoplay: true,
          autoplaySpeed: speed,
          speed: 900,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          asNavFor: '.js-pkgdetails-slider-for',
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 6,
                autoplay: false,
                arrows: false,
                dots: false,
              },
            },
          ],
        })
      })
    }
  }
}

export function formatPricing(price, discountedPrice, color) {
  const priceText = discountedPrice
    ? `
      <span
      style="color: ${color}"
      >
      <s>&#36;${price}</s>
      </span>
      <span>&#36;${discountedPrice}</span>`
    : `&#36;${price}`
  return priceText
}

function LotDropDown() {
  $(window).scroll(function () {
    let sectionTrigger = $('#pkg-details').offset()
    if (sectionTrigger) {
      if ($(window).scrollTop() >= sectionTrigger.top) {
        $('.header__pkghero').addClass('is-active')
      } else if ($(window).scrollTop() < sectionTrigger.top) {
        $('.header__pkghero').removeClass('is-active')
      }
    }
  })

  let lotDropDownBtn = $('.js-lotsize-btn')
  let lotDropDownMenu = $('.js-lotsize-menu')
  let lotDropDownItem = $('.js-lotsize-menu > li')
  let purchaseBtn = $('.js-purchase')
  let sendUrl = $('.js-lotsize-btn').data('origin')

  if (sendUrl) {
    sessionStorage.setItem(
      'send_url',
      sendUrl.replace('yardzen.wpenginepowered.com', 'yardzen.com')
    )
  }

  if ($('#lot-size-menu > li').length > 1) {
    lotDropDownBtn.click(function (event) {
      event.preventDefault()
      $(this).toggleClass('active')
      $(this).next().toggleClass('active')
      $(this).attr(
        'aria-expanded',
        $(this).attr('aria-expanded') === 'false' ? 'true' : 'false'
      )
    })

    lotDropDownItem.click(function (event) {
      event.preventDefault()
      $(event.currentTarget).parent().toggleClass('active')
      $(event.currentTarget).parent().prev().toggleClass('active')
      let lotSize = $(event.currentTarget).attr('data-size')
      let pkgPrice = $(event.currentTarget).attr('data-price')
      let pkgDiscountedPrice = $(event.currentTarget).attr(
        'data-discounted-price'
      )
      const pkgStrikethroughPriceColor = $(event.currentTarget).attr(
        'data-strikethrough-price-color'
      )
      const pkgPromoMessage = $(event.currentTarget).attr('data-promo-message')
      let lotSizeText = $(event.currentTarget).text()

      let pkgSlug = sessionStorage.getItem('package')
      let pathSlug = new URL(window.location.href).pathname
      let pkgSlugTest = pathSlug.split('/').join('')

      if (pkgSlug == null || pkgSlug != pkgSlugTest) {
        pkgSlug = pkgSlugTest
      }

      const priceText = formatPricing(
        pkgPrice,
        pkgDiscountedPrice,
        pkgStrikethroughPriceColor
      )

      $('.js-lotsize-btn > span').text(lotSizeText)
      $('.js-price > span').html(priceText)
      $('.js-promo > span').each(function () {
        $(this).text(pkgPromoMessage)
      })

      const sessionId = getSessionId()

      const pkgStylePagePath = window.location.pathname
      const inPkgStylePage = [
        '/first-time-homeowners-backyard',
        '/pet-lovers-backyard',
        '/kids-paradise-backyard',
        '/retirement-retreat-backyard',
        '/entertainers-backyard',
        '/wellness-backyard',
      ].includes(pkgStylePagePath)

      const pkgStyle = $('.pkghero__name').text()

      let newHref =
        sessionStorage.getItem('send_url') +
        '?package=' +
        (inPkgStylePage ? 'back-yard' : pkgSlug) +
        '&lotsize=' +
        (inPkgStylePage && !window.inPTest ? `${lotSize}_lifestyle` : lotSize) +
        '&yzsa=' +
        sessionId
      if (inPkgStylePage) {
        newHref += '&package-style=' + encodeURIComponent(pkgStyle)
      }

      purchaseBtn.attr('href', newHref)
      sessionStorage.setItem('lotsize', lotSize)
      sessionStorage.setItem('package', pkgSlug)

      $(event.currentTarget)
        .parent()
        .prev()
        .attr(
          'aria-expanded',
          $(event.currentTarget).parent().prev().attr('aria-expanded') ===
            'false'
            ? 'true'
            : 'false'
        )
    })

    $(document).on('keydown', function (event) {
      if (lotDropDownBtn.hasClass('active') && event.key == 'Escape') {
        lotDropDownBtn.attr('aria-expanded', 'false')
        lotDropDownBtn.removeClass('active')
        lotDropDownMenu.removeClass('active')
      }
    })

    $(document).on('focusin click', function (event) {
      var $target = $(event.target)
      if (!$target.closest('.pkghero__purchase-dropdown').length) {
        if (lotDropDownBtn.hasClass('active')) {
          lotDropDownBtn.attr('aria-expanded', 'false')
          lotDropDownBtn.removeClass('active')
          lotDropDownMenu.removeClass('active')
        }
      }
    })

    lotDropDownItem.keypress(function (event) {
      var key = event.which
      var $target = $(event.target)
      if (key == 13) {
        // Enter key
        lotDropDownItem.click()
        if (!$target.closest('.pkghero__purchase-dropdown').length) {
          if (lotDropDownBtn.hasClass('active')) {
            lotDropDownBtn.attr('aria-expanded', 'false')
            lotDropDownBtn.removeClass('active')
            lotDropDownMenu.removeClass('active')
          }
        }
      }
    })
  } else {
    $('.js-lotsize-menu').hide()
    lotDropDownBtn.addClass('is-disabled')
  }
}

export function PackageLotSelect(allowBVariants = false) {
  var url = new URL(window.location.href)
  if (window.location.href.includes('?')) {
    sessionStorage.setItem('receive_url', window.location.href)
    var baseUrl = url.origin

    if (url.searchParams.has('package')) {
      let paramPackage = url.searchParams.get('package')
      window.location.href = baseUrl + '/' + paramPackage
    }

    if (url.searchParams.has('lotsize')) {
      let paramLotsize = url.searchParams.get('lotsize')
      sessionStorage.setItem('lotsize', paramLotsize)
    }

    if (url.searchParams.has('is-recommended')) {
      let paramRecommended = url.searchParams.get('is-recommended')
      sessionStorage.setItem('recommended', paramRecommended)
      if (paramRecommended == 'true') {
        $('#is-recommended').addClass(' is-active')
      }
    }
  } else {
    sessionStorage.setItem('receive_url', '')
  }

  var receiveUrl = sessionStorage.getItem('receive_url')

  let pkgSlug = sessionStorage.getItem('package')
  let pathSlug = new URL(window.location.href).pathname
  let pkgSlugTest = pathSlug.split('/').join('')

  if (pkgSlug == null || pkgSlug != pkgSlugTest) {
    pkgSlug = pkgSlugTest
    sessionStorage.setItem('package', pkgSlug)
    let lotsizeCurrent = url.searchParams.get('lotsize')
      ? url.searchParams.get('lotsize')
      : 'standard'
    sessionStorage.setItem('lotsize', lotsizeCurrent)
  }

  let selectLotSize
  if (sessionStorage.getItem('lotsize')) {
    selectLotSize = allowBVariants
      ? sessionStorage.getItem('lotsize')
      : sessionStorage.getItem('lotsize').replace(/_b/, '')
  }

  var dropdownItem = document.querySelector(
    '.js-lotsize-menu > li[data-size=' + selectLotSize + ']'
  )
  let pkgPrice = dropdownItem?.getAttribute('data-price')
  const pkgDiscountedPrice = dropdownItem?.getAttribute('data-discounted-price')
  const pkgStrikethroughPriceColor = dropdownItem?.getAttribute(
    'data-strikethrough-price-color'
  )
  const pkgPromoMessage = dropdownItem?.getAttribute('data-promo-message')
  let lotSizeText = dropdownItem?.innerHTML

  const priceText = formatPricing(
    pkgPrice,
    pkgDiscountedPrice,
    pkgStrikethroughPriceColor
  )

  $('.js-lotsize-btn > span').each(function () {
    $(this).text(lotSizeText)
  })
  $('.js-price > span').each(function () {
    $(this).html(priceText)
  })
  $('.js-promo > span').each(function () {
    $(this).text(pkgPromoMessage)
  })

  const pkgStylePagePath = window.location.pathname
  const inPkgStylePage = [
    '/first-time-homeowners-backyard',
    '/pet-lovers-backyard',
    '/kids-paradise-backyard',
    '/retirement-retreat-backyard',
    '/entertainers-backyard',
    '/wellness-backyard',
  ].includes(pkgStylePagePath)

  const pkgStyle = $('.pkghero__name').text()

  let newHref =
    sessionStorage.getItem('send_url') +
    '?package=' +
    (inPkgStylePage ? 'back-yard' : pkgSlug) +
    '&lotsize=' +
    (inPkgStylePage && !window.inPTest
      ? `${selectLotSize}_lifestyle`
      : selectLotSize)

  if (inPkgStylePage) {
    newHref += '&package-style=' + encodeURIComponent(pkgStyle)
  }

  $('.js-purchase').each(function () {
    $('.js-purchase').attr('href', newHref)
  })
}

// function IsRecommended() {
//     var urlreferrer = document.referrer;

//     if (urlreferrer.includes('?')) {
//         let recommended = sessionStorage.getItem('recommended');
//         if(recommended == 'true') {
//             $('#is-recommended').addClass(' is-active');
//         }
//     } else {
//         sessionStorage.setItem('recommended', 'false');
//     }
// }
